i.fab {
  font-style: normal;
}

body {
  color: #fff;
  background-color: #000;
  font-family: Roboto, sans-serif;
  overflow-x: hidden;
}

header {
  text-align: center;
  margin-top: 3rem;
}

header h1 {
  color: #72efdd;
  margin: 0;
  font-family: Roboto Mono, monospace;
  font-size: 3rem;
}

header small {
  font-style: italic;
}

#social-buttons {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.social-button {
  color: #72efdd;
  border: 1px solid #72efdd;
  border-radius: 6px;
  margin: 5px;
  padding: 12px;
  text-decoration: none;
  transition: all .15s;
}

.social-button i {
  font-size: 1.1rem;
}

@media (any-hover: none) {
  .social-button:active {
    transform: scale(1.05);
  }

  .social-button:active.github {
    color: #fff;
    background-color: #171515;
    border-color: #171515;
  }

  .social-button:active.youtube {
    color: #fff;
    background-color: red;
    border-color: red;
  }

  .social-button:active.soundcloud {
    color: #fff;
    background-color: #f70;
    border-color: #f70;
  }
}

@media (any-hover: hover) {
  .social-button:hover {
    transform: scale(1.05);
  }

  .social-button:hover.github {
    color: #fff;
    background-color: #171515;
    border-color: #171515;
  }

  .social-button:hover.youtube {
    color: #fff;
    background-color: red;
    border-color: red;
  }

  .social-button:hover.soundcloud {
    color: #fff;
    background-color: #f70;
    border-color: #f70;
  }
}

.social-button span {
  display: none;
}

@media (width >= 460px) {
  .social-button {
    padding: 12px 20px;
  }

  .social-button span {
    display: inline;
  }
}

#outbound-transition {
  z-index: 999;
  width: 300vw;
  height: 300vw;
  border-radius: 100%;
  display: none;
  position: fixed;
}

#outbound-transition.github {
  background-color: #171515;
}

#outbound-transition.youtube {
  background-color: red;
}

#outbound-transition.soundcloud {
  background-color: #f70;
}

#outbound-transition.show {
  animation: .5s forwards outbound-transition;
  display: block;
}

@keyframes outbound-transition {
  0% {
    transform: translateX(-50%)translateY(-50%)scale(0);
  }

  100% {
    z-index: 999;
    transform: translateX(-50%)translateY(-50%)scale(1);
  }
}

#anti-garbage-msg {
  text-align: center;
  font-family: Roboto Mono;
  font-size: .7em;
  font-style: italic;
}

#anti-garbage-msg a {
  color: #3777ff;
}

main {
  justify-content: center;
  margin-top: 2rem;
  display: flex;
}

#side-by-side-spacer {
  width: 1rem;
  display: none;
}

#yt-videolist, #sc-tracklist {
  display: none;
}

@media (width >= 1400px) {
  #combined-list {
    display: none;
  }

  #yt-videolist, #side-by-side-spacer, #sc-tracklist {
    display: block;
  }

  main {
    margin-inline: 1rem;
  }
}

.list {
  width: 0;
  flex: 1 1 0;
  padding: 0 10px;
}

.upload-wrapper {
  color: #fff;
  background: #000;
  border: 1px solid #72efdd;
  border-radius: 15px;
  margin-bottom: 1em;
  padding: 10px;
  transition: all .15s;
  display: block;
  box-shadow: 0 2px 5px #72efdd;
  text-decoration: none !important;
}

@media (any-hover: none) {
  .upload-wrapper:active {
    border: 1px solid #cef9f3;
    transform: scale(1.01);
    box-shadow: 0 2px 20px #fff;
  }

  .upload-wrapper:active h3 {
    color: #cef9f3;
  }

  .upload-wrapper:active .fa-soundcloud {
    color: #ff9233;
  }

  .upload-wrapper:active .fa-youtube {
    color: #f33;
  }
}

@media (any-hover: hover) {
  .upload-wrapper:hover {
    border: 1px solid #cef9f3;
    transform: scale(1.01);
    box-shadow: 0 2px 20px #fff;
  }

  .upload-wrapper:hover h3 {
    color: #cef9f3;
  }

  .upload-wrapper:hover .fa-soundcloud {
    color: #ff9233;
  }

  .upload-wrapper:hover .fa-youtube {
    color: #f33;
  }
}

.upload-wrapper.fade-out {
  animation: .5s forwards fade-out;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    box-shadow: 0 2px 500px #cef9f3;
  }
}

.upload-wrapper > div {
  flex-direction: column;
  display: flex;
  position: relative;
}

@media only screen and (width >= 642px) {
  .upload-wrapper > div {
    flex-direction: row;
  }

  .upload-wrapper > div img {
    max-width: 14rem;
  }
}

.upload-wrapper > div img {
  object-fit: contain;
}

.upload-wrapper > div img.soundcloud {
  min-width: 14rem;
}

.upload-wrapper > div img.youtube {
  min-width: 20rem;
}

.upload-wrapper > div .content {
  padding: 5px 20px;
}

.upload-wrapper > div h3 {
  text-overflow: ellipsis;
  margin: 10px 0 0;
  transition: all .2s;
  overflow: hidden;
  transform: translateZ(0);
}

.upload-wrapper > div p {
  color: #999;
  text-overflow: ellipsis;
  max-width: 40em;
  max-height: 4em;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.upload-wrapper > div i {
  color: #ccc;
  transition: all .15s;
  position: absolute;
  bottom: 2px;
  right: 5px;
}

.upload-date {
  color: #e7d9ff;
  position: absolute;
  bottom: 1px;
  right: 32px;
}

.overlay:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
}

.overlay.soundcloud:before {
  animation: .25s forwards fill-soundcloud;
}

.overlay.youtube:before {
  animation: .25s forwards fill-youtube;
}

@keyframes fill-soundcloud {
  0% {
    background: none;
  }

  100% {
    background: #f70;
  }
}

@keyframes fill-youtube {
  0% {
    background: none;
  }

  100% {
    background: red;
  }
}

#error-msg {
  text-align: center;
  width: 100%;
  color: #ef7284;
  font-family: Roboto Mono, monospace;
  font-size: .8em;
}

/*# sourceMappingURL=index.61985069.css.map */
